@import '~font-awesome/css/font-awesome.css';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 1%;
  box-sizing: border-box;
}

/* nav bar css */
.nav-bar {
  height: 60px;
  background-color: #f8f9fa;
  color: #000;
  width: 100%;
}

.tpp-navbar {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-collapse-bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
}

.navbar-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-center {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}

.navbar-text {
  white-space: nowrap;
}

.nav-bar .navbar-brand,
.nav-bar .navbar-text,
.nav-bar .nav-link {
  color: #000;
}

.nav-bar-manager {
  height: 60px;
  background-color: #74c07a !important;
  color: #000;
  width: 100%;
}

.navbar-collapse-bg-manager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #74c07a;
}

.nav-bar-manager .navbar-brand,
.nav-bar-manager .navbar-text,
.nav-bar-manager .nav-link {
  color: #000;
}

/* header css */
.header {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #f8f9fa;
  padding: 1rem;
}

.header-toggle-button {
  border-width: 1px !important;
  margin: 0.5% 0.5% 0.5% 0;
  padding-top: 0.2% !important;
}

/* avatar css */
.user-info {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px #ccc;
  padding: 5%;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-email {
  margin-right: 10px;
}

/* side bar css */
.sidebar {
  min-width: 200px;
  background-color: #f8f9fa;
  padding-top: 60px;
}

/* breadcrumb css */
.breadcrumb {
  background-color: transparent !important;
  display: flex;
}

/* footer css */

.footer {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-top: auto;
}

.footer-left {
  flex: 1;
  text-align: left;
}

.footer-center {
  flex: 1;
  text-align: center;
}

.footer-right {
  flex: 1;
  text-align: right;
}

/* loading css */
.loading-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: progress;
  background-color: #51aded;
  opacity: 0.8;
}

.loading-background .loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
}

/* Agrid Table */
.ag-paging-panel {
  height: 50px !important;
  border-left: 1px solid #6c757d !important;
  border-right: 1px solid #6c757d !important;
  border-bottom: 1px solid #6c757d !important;
  border-top: none !important;
}

.ag-status-bar {
  display: none !important;
}

/* staff profile cards */
.staff-profile-card {
  margin-top: 1%;
}

.staff-profile-tabs {
  margin-top: 1%;
}

/* nav bar pills */
.nav-pills .nav-link.active {
  color: black !important;
  background-color: transparent !important;
  border: 1px solid #51aded !important;
}

.form-text-red {
  color: red !important;
}

/* responsive styles */
@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }

  .nav-bar,
  .nav-bar-manager {
    padding: 0.5rem;
  }

  .tpp-navbar {
    justify-content: flex-start;
  }

  .footer,
  .header {
    padding: 0.5rem;
  }
}

@media (max-width: 992px) {
  .navbar-collapse-bg {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .nav-center {
    justify-content: flex-start;
    width: auto;
  }

  .navbar-left {
    width: auto;
    justify-content: flex-start;
  }

  .navbar-toggler {
    margin-left: auto;
  }

  .avatar-email {
    display: none;
  }

  .avatar-button {
    margin-left: auto;
  }
}
